@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&family=Roboto:wght@500&display=swap');
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
/*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    background-color:hsl(0, 0%, 100%);
    color:#ffff;
    font-family: 'Raleway', sans-serif;
}

.heading_style {
    padding: 0px 0;
    text-align: center;
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
    font-weight: 500;
    color: #ffffff;
    background-color: rgb(17, 14, 14);
    font-size: 1.5rem;
    margin-bottom: 30px;

}

.cards {
    width: 100%;
    height: auto;
    
    
}
.card{
    width: 21%;
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0, 1);
    background: rgb(255, 255, 255);
    border: 5px solid black;
    border-radius: 12px;
    box-shadow: 0px 13px 10px -7px rgb(0,0,0,0.1);
    float: left;
    margin: 6%;

}
.card:hover {
    box-shadow: 0px 30px 18px -8px solid black;
    transform: scale(1.05, 1.05);
}
.card_img{
    height: 100%;
    width: 100%;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}
.card_info{
    background-color: #fff;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 16px 24px 24px 24px;
}
.card_info .card_category{
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
    font-weight: 500;
    color: #868686;
    background-color: #fff;
}
.card_info .card_title{
    margin-top: 5px;
    color: rgb(0, 0, 0);
    text-transform: capitalize;
    margin-bottom: 10px;
}

button{
    margin-top: 4px;
    margin-bottom: 10px;
    background-color: rgb(255, 255, 255);
    border: 1px solid black;
    border-radius: 5px;
    padding:10px;
    color:black;
}
button:hover{
    background-color: black;
    color: white;
    transition: .5s;
    cursor: pointer;
} */