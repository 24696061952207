@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@500&family=Roboto:wght@500&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
/*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    background-color:hsl(0, 0%, 100%);
    color:#ffff;
    font-family: 'Raleway', sans-serif;
}

.heading_style {
    padding: 0px 0;
    text-align: center;
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
    font-weight: 500;
    color: #ffffff;
    background-color: rgb(17, 14, 14);
    font-size: 1.5rem;
    margin-bottom: 30px;

}

.cards {
    width: 100%;
    height: auto;
    
    
}
.card{
    width: 21%;
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0, 1);
    background: rgb(255, 255, 255);
    border: 5px solid black;
    border-radius: 12px;
    box-shadow: 0px 13px 10px -7px rgb(0,0,0,0.1);
    float: left;
    margin: 6%;

}
.card:hover {
    box-shadow: 0px 30px 18px -8px solid black;
    transform: scale(1.05, 1.05);
}
.card_img{
    height: 100%;
    width: 100%;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}
.card_info{
    background-color: #fff;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 16px 24px 24px 24px;
}
.card_info .card_category{
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
    font-weight: 500;
    color: #868686;
    background-color: #fff;
}
.card_info .card_title{
    margin-top: 5px;
    color: rgb(0, 0, 0);
    text-transform: capitalize;
    margin-bottom: 10px;
}

button{
    margin-top: 4px;
    margin-bottom: 10px;
    background-color: rgb(255, 255, 255);
    border: 1px solid black;
    border-radius: 5px;
    padding:10px;
    color:black;
}
button:hover{
    background-color: black;
    color: white;
    transition: .5s;
    cursor: pointer;
} */
* {
    margin: 0;
  }
  
  .App {
    background-color: #111;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

.row {
  color: rgb(251, 251, 251);
  margin-left: 20px;
}

.row__posters {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  padding: 20px;
}

.row__posters::-webkit-scrollbar {
  display: none;
}

.row__poster {
  object-fit: contain;
  width: 100%;
  max-height: 100px;
  margin-right: 10px;
  transition: transform 450ms;
}

.row__poster:hover {
  transform: scale(1.08);
}

.row__posterLarge {
  max-height: 250px;
}

.row__posterLarge:hover {
  transform: scale(1.09);
}

.nav{
    position: fixed; 
    top: 0px;
    width: 100%;
    padding: 20px;
    height: 30px;
    z-index: 1;

    transition-timing-function: ease-in ;
    transition: all 0.5s;
    
}

.nav__black {
    background-color:#111;

}

.nav__logo {
    position: fixed;
    left: 20px;
    width: 80px;
    object-fit: contain;

}

.nav__avatar {
    position: fixed;
    right: 20px;
    width: 30px;
    object-fit: contain;
}
.banner {
    color: white;
    object-fit: contain;
    height: 448px;
  }
  
  .banner__contents {
    margin-left: 30px;
    padding-top: 140px;
    height: 190px;
  }
  
  .banner__title {
    font-size: 3rem;
    font-weight: 800;
    padding-bottom: 0.3rem;
  }
  
  .banner__description {
    width: 45rem;
    line-height: 1.3;
    padding-top: 1rem;
    font-size: 0.8rem;
    max-width: 360px;
    height: 80px;
  }
  
  .banner__button {
    cursor: pointer;
    color: #fff;
    outline: none;
    border: none;
    font-weight: 700;
    border-radius: 0.2vw;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: rgba(51, 51, 51, 0.5);
  }
  
  .banner__button:hover {
    color: #000;
    background-color: #e6e6e6;
    transition: all 0.2s;
  }
  
  .banner--fadeBottom {
    height: 7.4rem;
    background-image: linear-gradient(
      180deg,
      transparent,
      rgba(37, 37, 37, 0.61),
      #111
    );
  }
  
