.row {
  color: rgb(251, 251, 251);
  margin-left: 20px;
}

.row__posters {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  padding: 20px;
}

.row__posters::-webkit-scrollbar {
  display: none;
}

.row__poster {
  object-fit: contain;
  width: 100%;
  max-height: 100px;
  margin-right: 10px;
  transition: transform 450ms;
}

.row__poster:hover {
  transform: scale(1.08);
}

.row__posterLarge {
  max-height: 250px;
}

.row__posterLarge:hover {
  transform: scale(1.09);
}
