.nav{
    position: fixed; 
    top: 0px;
    width: 100%;
    padding: 20px;
    height: 30px;
    z-index: 1;

    transition-timing-function: ease-in ;
    transition: all 0.5s;
    
}

.nav__black {
    background-color:#111;

}

.nav__logo {
    position: fixed;
    left: 20px;
    width: 80px;
    object-fit: contain;

}

.nav__avatar {
    position: fixed;
    right: 20px;
    width: 30px;
    object-fit: contain;
}